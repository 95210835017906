<template>
  <div>
    <div class="page-container addBusiness-fix">
      <validation-observer ref="rule">
        <b-media>
          <template #aside>
            <div id="appImgBusinessSo">
              <div
                v-if="valueData.avatar&&valueData.avatar!==null&&valueData.avatar!==''"
                class="image-input image-input-active d-flex"
              >
                <div
                  class="image-preview"
                >
                  <img
                    class="img-responsive h-100"
                    :src="($serverfile+valueData.avatar)"
                  >
                  <b-dropdown
                    class="remove-file"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="CameraIcon"
                        size="16"
                        class="icon-camera-hover"
                      />
                    </template>
                    <b-dropdown-item
                      @click="openinput"
                    >
                      <feather-icon
                        icon="UploadIcon"
                        class="mr-50"
                      />
                      <span>Tải ảnh lên</span>
                    </b-dropdown-item>
                    <input
                      id="vue-file-upload-input"
                      type="file"
                      class="d-none"
                      @change="addImage"
                    >
                    <b-dropdown-item
                      @click="removeFile(index)"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                        class="mr-50"
                      />
                      <span>Xóa ảnh</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div
                v-else
                class="image-input image-input-tbd d-flex"
              >
                <div
                  class="image-preview dropzone d-flex justify-content-center align-items-center"
                  @click="openinput"
                >
                  <feather-icon
                    icon="CameraIcon"
                    class="icon-camera"
                  />
                </div>
                <input
                  id="vue-file-upload-input"
                  type="file"
                  class="d-none"
                  @change="addImage"
                >
              </div>
            </div>
          </template>
        </b-media>
        <information-and-adress-title
          :icon="'InfoIcon'"
          :title="'Thông tin'"
        />
        <div class="infomation-and-adress__input__01">
          <b-row>
            <!--===============  input mã doanh nghiệp =================-->
            <b-col
              v-if="province !== 'Đắk Lắk'"
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="basicInput"
              >
                <label
                  for="code"
                >Mã doanh nghiệp<span
                  class="required"
                >(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Mã doanh nghiệp là bắt buộc`,}"
                >
                  <b-form-input
                    id="code"
                    v-model="valueData.code"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-else
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="basicInput"
              >
                <label
                  for="code"
                >Mã doanh nghiệp</label>
                <b-form-input
                  id="code"
                  v-model="valueData.taxNumber"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!--===============  input tên doanh nghiệp =================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="name"
              >
                <label
                  for="name"
                >Tên doanh nghiệp<span
                  class="required"
                >(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Tên doanh nghiệp là bắt buộc`,}"
                >
                  <b-form-input
                    id="name"
                    v-model="valueData.name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--===============  input tên doanh nghiệp tiếng anh=================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="englishName"
              >
                <label
                  for="englishName"
                >Tên DN tiếng anh</label>
                <b-form-input
                  id="englishName"
                  v-model="valueData.englishName"
                />
              </b-form-group>
            </b-col>
            <!--===============  input tên doanh nghiệp viết tắt=================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="abbreviationName"
              >
                <label
                  for="abbreviationName"
                >Tên DN viết tắt</label>
                <b-form-input
                  id="abbreviationName"
                  v-model="valueData.abbreviationName"
                />
              </b-form-group>
            </b-col>
            <!--===============  input số quyết định thành lập=================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="name12"
              >
                <label
                  for="name12"
                >Số quyết định thành lập</label>
                <b-form-input
                  id="name12"
                  v-model="valueData.decisionEstablish"
                />
              </b-form-group>
            </b-col>

            <!--===============  input cơ quan quản lý cấp trên=================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="abbreviationName"
              >
                <label
                  for="abbreviationName"
                >Cơ quan quản lý cấp trên</label>
                <b-form-input
                  id="abbreviationName"
                  v-model="valueData.managementAgencyName"
                />
              </b-form-group>
            </b-col>
            <!--===============  input Đơn vị cấp=================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="unitIssued"
              >
                <label
                  for="unitIssued"
                >Đơn vị cấp</label>
                <b-form-input
                  id="unitIssued"
                  v-model="valueData.unitIssued"
                />
              </b-form-group>
            </b-col>
            <!--===============  input Ngày cấp=================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="dateIssuedString"
              >
                <label
                  for="dateIssuedString"
                >Ngày cấp</label>
                <date-time-picker
                  v-model="valueData.dateIssuedString"
                  :disabledInput="true"
                />
              </b-form-group>
            </b-col>
            <!--===============  input Đơn vị cấp=================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="unitIssued"
              >
                <label
                  for="unitIssued"
                >Nơi cấp</label>
                <b-form-input
                  id="unitIssued"
                  v-model="valueData.locationIssued"
                />
              </b-form-group>
            </b-col>
            <!--===============  input ngày hết hạng giấy phép kinh doanh=================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="dateExpiredString"
              >
                <label
                  for="dateExpiredString"
                >Ngày hết hạn giấy phép kinh doanh (Nếu ngày hết hạn vô thời hạn thì để trống)</label>
                <date-time-picker
                  v-model="valueData.dateExpiredString"
                  :disabledInput="true"
                  :showClose="true"
                />
              </b-form-group>
            </b-col>
            <!--===============  input Mã số thuế=================-->
            <b-col
              v-if="province === 'Đắk Lắk'"
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="taxNumber"
              >
                <label
                  for="taxNumber"
                >Mã số thuế</label>
                <b-form-input
                  id="taxNumber"
                  v-model="valueData.taxNumber"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              v-else
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="taxNumber"
              >
                <label
                  for="taxNumber"
                >Mã số thuế<span
                  class="required"
                >(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Mã số thuế là bắt buộc`,}"
                >
                  <b-form-input
                    id="taxNumber"
                    v-model="valueData.taxNumber"
                    :state="errors.length > 0 ? false:null"
                    :disabled="province === 'Đắk Lắk' ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--===============  input Loại hình doanh nghiệp  =================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="basicInput"
              >
                <label
                  for="label"
                >Loại hình doanh nghiệp<span
                  class="required"
                >(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Loại hình doanh nghiệp là bắt buộc`,}"
                >
                  <v-select
                    v-model="valueData.bussinessTypeId"
                    :reduce="data => data.id"
                    :label="'name'"
                    :class="{'is-invalid': !!errors.length}"
                    :placeholder="`Lựa chọn loại hình doanh nghiệp `"
                    :options="selectbussinessType || []"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--===============  input Ngành nghề kinh doanh=================-->
            <b-col
              md="4"
              xl="4"
              class="valid-border"
            >
              <b-form-group
                label-for="bussinessSectorId"
              >
                <label for="InputHelp">Ngành nghề kinh doanh chính<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Ngành nghề kinh doanh chính là bắt buộc`,}"
                >
                  <business-sector-select
                    v-model="valueData.bussinessSectorId"
                    :class="{'is-invalid': !!errors.length}"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--===============  input loại doanh nghiệp=================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="bussinessClass"
              >
                <label
                  for="bussinessClass"
                >Loại doanh nghiệp</label>
                <v-select
                  v-model="valueData.bussinessClass"
                  :reduce="data => data.id"
                  :label="'name'"
                  :placeholder="`Lựa chọn loại doanh nghiệp `"
                  :options="selectbussinessClassify"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
              </b-form-group>

            </b-col>
            <!--===============  input tài khoản=================-->
            <b-col
              v-if="province === 'Đắk Lắk'"
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="userName"
              >
                <label
                  for="userName"
                >Tên đăng nhập<span
                  class="required"
                >(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Tên đăng nhập là bắt buộc`,}"
                >
                  <b-form-input
                    id="userName"
                    v-model="valueData.taxNumber"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-else
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="userName"
              >
                <label
                  for="userName"
                >Tên đăng nhập<span
                  class="required"
                >(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Tên đăng nhập là bắt buộc`,}"
                >
                  <b-form-input
                    id="userName"
                    v-model="valueData.userName"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--===============  input Mật khẩu=================-->
            <b-col
              v-if="!edit"
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="password"
              >
                <label
                  for="password"
                >Mật khẩu<span
                  class="required"
                >(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Mật khẩu là bắt buộc`,}"
                >
                  <b-form-input
                    id="password"
                    v-model="valueData.password"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--===============  input Nhập lại mật khẩu=================-->
            <b-col
              v-if="!edit"
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="againPassworks"
              >
                <label
                  for="againPassworks"
                >Nhập lại mật khẩu<span
                  class="required"
                >(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Nhập lại mật khẩu là bắt buộc`,}"
                >
                  <b-form-input
                    id="againPassworks"
                    v-model="valueData.rePassword"
                    :state="errors.length > 0 ? false:null"
                    @keyup="checkPassword"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="againPassworks"
                    class="text-danger"
                  > Nhập lại mật khẩu không đúng</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Kiểu người dùng-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group label-for="basicInput">
                <label for="InputHelp">Kiểu người dùng <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="userTypeId"
                  rules="required"
                  :custom-messages="{required: `Kiểu người dùng là bắt buộc`,}"
                >
                  <v-select
                    v-model="valueData.userTypeId"
                    :reduce="userType => userType.id"
                    label="name"
                    placeholder="Lựa chọn kiểu người dùng"
                    :options="dataCombobox || []"
                    :class="{'is-invalid': !!errors.length}"
                  >
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      {{ option.name }}
                    </template>
                    <template #selected-option-container="{ option }">
                      <div class="vs__selected">
                        {{ option.name }}
                      </div>
                    </template>
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="wardId"
              >
                <label
                  for="wardId"
                >Phòng trực thuộc</label>
                <v-select
                  v-model="valueData.departmentId"
                  :reduce="data => data.id"
                  :label="'name'"
                  :placeholder="`Lựa chọn phòng trực thuộc `"
                  :options="department"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <information-and-adress-title
          :icon="'MapPinIcon'"
          :title="'Địa chỉ'"
        />
        <div class="infomation-and-adress__input__02">
          <b-row>
            <!--===============  input Địa chỉ=================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="address"
              >
                <label
                  for="address"
                >Địa chỉ</label>
                <b-form-input
                  id="address"
                  v-model="valueData.address"
                />
              </b-form-group>
            </b-col>
            <!--===============  input Địa chỉ chi nhánh=================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="addressBranch"
              >
                <label
                  for="addressBranch"
                >Địa chỉ chi nhánh</label>
                <b-form-input
                  id="addressBranch"
                  v-model="valueData.addressBranch"
                />
              </b-form-group>
            </b-col>
            <!--===============  input Website =================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="website"
              >
                <label
                  for="website"
                >Website</label>
                <b-form-input
                  id="website"
                  v-model="valueData.website"
                />
              </b-form-group>
            </b-col>
            <!--===============  input Số điện thoại =================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="phoneNumber"
              >
                <label
                  for="phoneNumber"
                >Số điện thoại</label>
                <b-form-input
                  id="phoneNumber"
                  v-model="valueData.phoneNumber"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <!--===============  input Email =================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="email"
              >
                <label
                  for="email"
                >Email</label>
                <b-form-input
                  id="email"
                  v-model="valueData.email"
                />
              </b-form-group>
            </b-col>
            <!--===============  input Fax =================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="Fax"
              >
                <label
                  for="Fax"
                >Fax</label>
                <b-form-input
                  id="Fax"
                  v-model="valueData.fax"
                />
              </b-form-group>
            </b-col>

            <!--===============  select Tỉnh/Thành phố =================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="provinceId"
              >
                <label
                  for="provinceId"
                >Tỉnh/Thành phố</label>
                <v-select
                  v-model="valueData.provinceId"
                  :reduce="data => data.id"
                  :label="'name'"
                  :placeholder="`Lựa chọn tỉnh/Thành phố `"
                  :options="provinces"
                  @input="seclectProvinces"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <!--===============  select Quận/Huyện=================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="wardId"
              >
                <label
                  for="districtId"
                >Quận/Huyện</label>
                <v-select
                  v-model="valueData.districtId"
                  :reduce="data => data.id"
                  :label="'name'"
                  :placeholder="`Lựa chọn quận/Huyện `"
                  :options="districts"
                  @input="seclectDistricts"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <!--===============  select Phường/Xã=================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group
                label-for="wardId"
              >
                <label
                  for="wardId"
                >Phường/Xã</label>
                <v-select
                  v-model="valueData.wardId"
                  :reduce="data => data.id"
                  :label="'name'"
                  :placeholder="`Lựa chọn phường/Xã `"
                  :options="wards"
                  @input="seclectWards"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">
                      Không có kết quả.
                    </template>
                    <em
                      v-else
                    >Không có dữ liệu</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

          </b-row>
        </div>
      </validation-observer>
      <div class="add-business__button">
        <div class="add-business__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="save()"
          >
            Lưu lại
          </b-button>
        </div>
        <div class="add-business__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="save01"
          >
            Quay về
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapState, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import {
  BRow, BCol, BFormInput, BFormGroup, BMedia, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

import InformationAndAdressTitle from './components/InformationAndAdressTitle.vue'
import BusinessSectorSelect from '@/views/management-business/business-sector/pages/components/BusinessSectorSelect.vue'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import storeDefault from '@/home/store'

export default {
  components: {
    // InformationAndAdress,
    InformationAndAdressTitle,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMedia,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BRow,
    BCol,
    BusinessSectorSelect,
    BFormInput,
    DateTimePicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isRendered: true,
      required,
      selectbussinessClass: [
        {
          id: 'StateEnterprises',
          name: 'Doanh nghiệp nhà nước',
        },
        {
          id: 'NonstateEnterPrises',
          name: 'Doanh nghiệp nước ngoài',
        },
        {
          id: 'ForeignInvestedEnterprises',
          name: 'Doanh nghiệp có vốn đầu tư nước ngoài',
        },
        {
          id: 'Organization',
          name: 'Tổ chức',
        },
        {
          id: 'JobCenter',
          name: 'Trung tâm giới thiệu việc làm',
        },
      ], //  Loại doanh nghiệp
      selectbussinessClassify: [
        {
          id: 'Bussiness',
          name: 'Doanh nghiệp',
        },
        {
          id: 'Accreditation',
          name: 'Tổ chức kiểm định',
        },
        {
          id: 'Training',
          name: 'Tổ chức huấn luyện',
        },
        {
          id: 'Health',
          name: 'Trung tâm y tế',
        },
        {
          id: 'JobCenter',
          name: 'Trung tâm giới thiệu việc làm',
        },
        {
          id: 'StateAgencies',
          name: 'Quận/Huyện, Phường/Xã',
        },
      ], // data lựa chọn phân loại doanh nghiệp
      selectbussinessType: [], // data lựa chọn Loại hình doanh nghiệp
      valueData: {
        code: null, // mã doanh nghiệp    đã gắn  value
        name: null, // tên doanh nghiệp     đã gắn  value
        englishName: null, // tên tiếng anh  đã gắn  value
        abbreviationName: null, // tên viết tắt   đã gắn  value
        decisionEstablish: null, // số quyết định  đã gắn  value
        website: null, // trang web đã gắn  value
        unitIssued: null, // đơn vị cấp    đã gắn  value
        phoneNumber: null, // số dt    đã gắn  value
        fax: null, // fax   đã gắn  value
        locationIssued: null, // mơi cấp    đã gắn  value
        email: null, // email   đã gắn  value
        taxNumber: null, // mã số thuế đã gắn  value---------------
        bussinessTypeId: null, // kiểu loại hình doành nghiệp
        address: null, // địa chỉ   đã gắn  value------------------
        bussinessSectorId: null, // ngành nghề kinh doanh
        addressBranch: null, // địa chỉ chi nhánh   đã gắn  value------------------
        officalProfileId: null,
        userName: null, // tên            ------------đã gắn  value
        password: null, // mật khẩu   ------------đã gắn  value
        emailConfirmed: true,
        bussinessClassify: 'Bussiness', // dã gắn value
        bussinessClass: null, // dã gắn value
        dateExpiredString: null, // ngày hết hạn
        dateIssuedString: null, // ngày cấp
        provinceId: 0,
        districtId: 0,
        wardId: 0,
        rePassword: null,
        managementAgencyName: null, // Cơ quan quản lý cấp trên
        userTypeId: null, // kiểu người dùng
        avatar: null,
        departmentId: null,
      },
      provinces: [],
      districts: [],
      wards: [],
      department: [],
      edit: false,
      againPassworks: false,
      province: storeDefault.province,
    }
  },
  computed: {
    ...mapState('business', ['dataEdit']),
    ...mapGetters('userType', ['dataCombobox']),
  },
  // created() {
  //   if (this.$route.params.id) {
  //     this.fetchdataIdST(this.$route.params.id)
  //   }
  // },
  mounted() {
    this.doFetchUserTypeCombobox()
    if (this.$route.params.id) {
      this.fetchdataIdST(this.$route.params.id)
    }
  },
  async created() {
    this.getComboboxDepartment()
    const dataBusinessType = await this.fecthDataBusinessType()
    this.selectbussinessType = dataBusinessType.data.data
    this.provinces = await this.fecthProvinces()
    // this.districts = await this.fecthDistricts(this.valueData.provinceId)
    // this.wards = await this.fecthWards(this.valueData.districtId)
    if (this.$route.params.id) {
      this.valueData = this.dataEdit
      this.districts = await this.fecthDistricts(this.valueData.provinceId)
      this.wards = await this.fecthWards(this.valueData.districtId)
      delete this.valueData.officalProfileId
      this.edit = true
    }
  },

  methods: {
    ...mapActions('business', ['fecthDataBusinessType', 'fecthProvinces', 'fecthDistricts', 'fecthWards', 'fetchdataIdST']),
    ...mapActions('userType', [
      'doFetchUserTypeCombobox',
    ]),
    ...mapActions('users', ['uploadFile']),
    async getComboboxDepartment() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_DEPARTMENT)
      this.department = data?.data
    },
    // account
    openinput() {
      this.$hideAllPageLoading()
      document.getElementById('vue-file-upload-input').click()
      this.$hideAllPageLoading()
    },
    uploadPhoto() {
      this.$showAllPageLoading()
      this.openinput()
      this.$hideAllPageLoading()
    },
    async addImage(e) {
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      const model = {
        files: file,
        isSecure: false,
      }
      const response = await this.uploadFile(model)
      this.valueData.avatar = `${response}`
    },
    removeFile(index) {
      this.valueData.avatar = null
    },

    // Check nhập lại mật khẩu
    checkPassword() {
      if (this.valueData.password !== this.valueData.rePassword && this.valueData.password !== '') {
        this.againPassworks = true
      } else {
        this.againPassworks = false
      }

      if (this.valueData.rePassword === '') {
        this.againPassworks = false
      }
    },

    // ******Lựa chon thành phố********
    async seclectProvinces(e) {
      this.valueData.provinceId = e
      this.districts = await this.fecthDistricts(this.valueData.provinceId)
    },
    //* ******Lựa chọn huyện ********* */
    async seclectDistricts(e) {
      this.valueData.districtId = e
      this.wards = await this.fecthWards(this.valueData.districtId)
    },
    async seclectWards(e) {
      this.valueData.wardId = e
    },
    // ...mapActions('business', ['fetchdataIdST']),
    // ============================================action api=========================================

    // eslint-disable-next-line consistent-return
    async addBusiness(dataAdd) {
      const payload = {
        ...dataAdd,
        code: this.province === 'Đắk Lắk' ? dataAdd.taxNumber : dataAdd.code,
        userName: this.province === 'Đắk Lắk' ? dataAdd.taxNumber : dataAdd.userName,
        bussinessClass: dataAdd.bussinessClass ? dataAdd.bussinessClass : 'Bussiness',
      }
      this.$showAllPageLoading()
      try {
        const addBusiness01 = await axiosApiInstance.post(ConstantsApi.ADD_BUSINESS, payload)
        if (addBusiness01.status === 200) {
          this.$root.$bvToast.toast('Thêm thành công!', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$router.push({ name: 'business' })
          this.$hideAllPageLoading()
        }
      } catch (e) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    //* *******edit ********8 */
    // eslint-disable-next-line consistent-return
    async editST(dataAdd) {
      const payload = {
        ...dataAdd,
        code: this.province === 'Đắk Lắk' ? dataAdd.taxNumber : dataAdd.code,
        userName: this.province === 'Đắk Lắk' ? dataAdd.taxNumber : dataAdd.userName,
        bussinessClass: dataAdd.bussinessClass ? dataAdd.bussinessClass : 'Bussiness',
      }
      this.$showAllPageLoading()
      try {
        const PostEditJod = await axiosApiInstance.put(ConstantsApi.EDITST, payload)
        if (PostEditJod.status === 200) {
          this.$root.$bvToast.toast('Sửa thành công!', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
        this.$router.push({ name: 'business' })
        this.$hideAllPageLoading()
      } catch (e) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    // ==============================================action handle=======================================
    data01() {
      return this.$refs.informationAndAdress.valueCodeRef
    },

    async save() {
      this.$refs.rule.validate().then(async success => {
        if (success) {
          const data = {
            ...this.valueData,
          }
          if (this.$route.params.id) {
            await this.editST(data)
          } else {
            await this.addBusiness(data)
          }
        } else {
          let { errors } = this.$refs.rule
          errors = Object.values(errors).filter(item => item[0]?.includes('là bắt buộc'))
          errors = errors.map((item, index) => {
            if (index !== 0) {
              return ` ${item[0].slice(0, item[0].length - 12)}`
            }
            return item[0].slice(0, item[0].length - 12)
          })
          this.$root.$bvToast.toast(`Vui lòng điền thông tin ${errors}`, {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      })
    },
    save01() {
      this.$router.push({ name: 'business' })
    },
  },
}
</script>

<style lang="scss">
.add-business__button{
  // padding:0px 20px 20px 20px;
  display: flex;
  &__item{
    margin-right: 20px;
  }
}

.valid-border {
  .is-invalid {
    border: 0.5px solid red !important;
    border-radius: 5px;
    .vue-treeselect__control {
      border: unset !important;
    }
  }
}

#appImgBusinessSo {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  margin-right: 20px;
  .btn {
    padding: 4px !important;
  }
  .image-input {
    padding: 3px;
  }

  .image-preview {
    width: 96px;
    height: 96px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 6px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .icon-camera {
    color: white;
    height: 50px;
    width: 50px;
  }
  .dropzone {
    width: 96px;
    height: 96px;
  }
  .dropzone {
    background: #efefef;
    border-radius: 7%;
  }
  .remove-file {
    position: absolute;
    border-radius: 20px;
    margin-top: 28px;
    margin-left: -66px;
    width: 40px;
    height: 40px;
  }
  .remove-file:hover {
    color: white;
    background-color: rgba(130, 134, 139, 0.65);
  }
  .icon-camera-hover {
    width: 28px;
    height: 28px;
    color: rgba(104, 104, 104, 0);
  }
  .icon-camera-hover:hover {
    color: white;
  }
}

</style>
